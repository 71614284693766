export const DAYS_PER_YEAR = 365;
export const DAYS_PER_MONTH = 31;
export const DAYS_PER_WEEK = 7;
export const DAYS_PER_TWO_MONTH = 62;
export const DAYS_PER_THREE_MONTH = 90;
export const DAYS_PER_FOUR_MONTH = 121;
export const DAYS_PER_FIVE_MONTH = 152;
export const DAYS_PER_SIX_MONTH = 182;
export const DAYS_PER_SEVEN_MONTH = 212;
export const DAYS_PER_EIGHT_MONTH = 243;
export const DAYS_PER_NINE_MONTH = 273;
export const DAYS_PER_TEN_MONTH = 304;
export const DAYS_PER_ELEVEN_MONTH = 334;

export const PER_WEEK = 'per_week';

export const VIP_SUPPORT = 'vip_support';

export const PAYMENT_TYPES = {
    CREDIT_CARD: 1,
    PAYPAL: 4,
    APPLE_PAY: 1,
    GOOGLE_PAY: 1,
} as const;

export const PAYMENT_TYPES_NAME = {
    CREDIT_CARD: 'Bank_Card',
    PAYPAL: 'PayPal',
    APPLE_PAY: 'apple_pay',
    ONE_CLICK_LITERAL: 'apple_pay',
    GOOGLE_PAY: 'google_pay',
};

export const PAYMENT_TYPES_NAME_ID = {
    CREDIT_CARD: 'bankCard',
    PAYPAL: 'payPal',
    APPLE_PAY: 'paymentGenerator',
    GOOGLE_PAY: 'paymentGenerator',
};

export const PAYMENT_EVENT_NAMES = {
    applebtn: 'apple_pay',
    googlebtn: 'google_pay',
    form: 'Bank_Card',
    paypal: 'paypal',
    resign: 'resign',
};

export const CREDIT_CARD_LITERAL = 'CREDIT_CARD';
export const PAYPAL_LITERAL = 'PAYPAL';
export const APPLE_PAY_LITERAL = 'APPLE_PAY';
export const GOOGLE_PAY_LITERAL = 'GOOGLE_PAY';
export const ONE_CLICK_LITERAL = 'ONE_CLICK_LITERAL';

export const ONE_CLICK_LITERALS = [ONE_CLICK_LITERAL, APPLE_PAY_LITERAL, PAYPAL_LITERAL, GOOGLE_PAY_LITERAL];

export const DEVICE_OS = {
    ANDROID: 'ANDROID',
    IOS: 'IOS',
};

export const PAYMENT_FLOW_PERIODS = [
    DAYS_PER_MONTH,
    DAYS_PER_TWO_MONTH,
    DAYS_PER_THREE_MONTH,
    DAYS_PER_FOUR_MONTH,
    DAYS_PER_FIVE_MONTH,
    DAYS_PER_SIX_MONTH,
    DAYS_PER_SEVEN_MONTH,
    DAYS_PER_EIGHT_MONTH,
    DAYS_PER_YEAR,
    DAYS_PER_WEEK,
];

// Introductory flow
export const INTRODUCTORY_A_FLOW_PAYMENT_ID = 'payment_Aa_checkout_Alpha1';
export const INTRODUCTORY_B_FLOW_PAYMENT_ID = 'payment_Ab_Checkout_Alpha1';

export const NOTIFICATION_WINDOW_SET_UP_FOR_VALIDATE_FAILURE = {
    status: true,
    title: 'paymentFlow.validationError.title',
    issueType: 'ERROR',
    issueButtonTitle: 'paymentFlow.validationError.button',
    description: 'paymentFlow.validationError.description',
};

export const HARDCODED_PRICE_FOR_FIRST_TRIAL_PRODUCT_ON_PAYMENTG4FOXTROT16 = 30.99;

export const PRODUCT_CODES = {
    MAIN: 'main',
    GUIDE_SLEEPING_GUIDE_NUTRITION: 'guide_sleeping__guide_nutrition',
    GUIDE_NUTRITION: 'guide_nutrition',
    GUIDE_SLEEPING: 'guide_sleeping',
    BELLY: 'belly',
    BELLY_SEVENMIN: 'belly_sevenmin',
    SEVENMIN: 'sevenmin',
};

export const SOLID__NOT_STANDARD_ERROR = 'SOLID_NOT_STANDARD_ERROR';

export const GET_MY_PLAN_BTN = 'get my plan';

export const PAY_PAL_INIT_ID = 'paypal-button';
